<div class="theme-container">
  <ng-container *ngIf="mode === 'verifyEmail'">
    <h1 style="text-align: center">
      {{ 'emailvalidation.validated' | translate }}
    </h1>
    <h2 style="text-align: center" class="mb-7">
      {{ 'emailvalidation.access' | translate }}
    </h2>
    <div class="mb-7">&nbsp;</div>
    <div fxLayout="row wrap" fxLayoutAlign="space-around start">
      <mat-card class="appt-card" style="text-align: center">
        <mat-card-title>{{
          'emailvalidation.registeredCG' | translate
        }}</mat-card-title>
        <div class="mb-7">&nbsp;</div>
        <mat-card-actions>
          <button mat-raised-button color="primary" (click)="cgLogin()">
            {{ 'emailvalidation.loginCG' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
      <!-- <mat-card class="appt-card" style="text-align: center">
        <mat-card-title>{{
          'emailvalidation.registeredCS' | translate
        }}</mat-card-title>
        <div class="mb-7">&nbsp;</div>
        <mat-card-actions>
          <button mat-raised-button color="warn" (click)="csLogin()">
            {{ 'emailvalidation.loginCS' | translate }}
          </button>
        </mat-card-actions>
      </mat-card> -->
    </div>
    <div class="mb-7">&nbsp;</div>
    <h6 style="text-align: center">
      {{ 'emailvalidation.info1' | translate }}
    </h6>
    <h6 style="text-align: center">
      {{ 'emailvalidation.info2' | translate }}
      <a href="mailto:info@care4giver.com">info&#64;care4giver.com</a
      >{{ 'emailvalidation.info3' | translate }}
    </h6>
  </ng-container>
  <ng-container *ngIf="mode === 'resetPassword'">
    <h1 style="text-align: center">
      {{ 'emailvalidation.reset' | translate }}
    </h1>
    <div fxLayout="row" fxLayoutAlign="center center" class="my-3">
      <mat-card [style.max-width.px]="500">
        <form
          [formGroup]="loginForm"
          (ngSubmit)="onSubmitChangePassword(loginForm.value)"
        >
          <mat-form-field appearance="outline" class="w-100 mt-1">
            <mat-icon matPrefix class="mr-1 text-muted">lock</mat-icon>
            <mat-label>Password</mat-label>
            <input
              matInput
              placeholder="Password"
              formControlName="password"
              required
              [type]="hide ? 'password' : 'text'"
            />
            <mat-error *ngIf="loginForm.controls.password.errors?.required"
              >Password is required</mat-error
            >
            <mat-error *ngIf="loginForm.controls.password.hasError('minlength')"
              >Password isn't long enough, minimum of 6 characters</mat-error
            >
            <button
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              type="button"
              class="text-muted"
            >
              <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100 mt-1">
            <mat-icon matPrefix class="mr-1 text-muted">lock</mat-icon>
            <mat-label>Password for validation</mat-label>
            <input
              matInput
              placeholder="Password"
              formControlName="passwordAgain"
              required
              [type]="hide ? 'password' : 'text'"
            />
            <mat-error
              *ngIf="
                loginForm.controls.passwordAgain.value !==
                loginForm.controls.password.value
              "
              >Passwords must match</mat-error
            >
            <button
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              type="button"
              class="text-muted"
            >
              <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
          </mat-form-field>

          <div class="text-center mt-2">
            <button
              mat-raised-button
              color="accent"
              class="uppercase"
              type="submit"
              [disabled]="!loginForm.valid"
            >
              Reset Password
            </button>
          </div>
        </form>
      </mat-card>
    </div>
  </ng-container>
  <ng-container *ngIf="mode === 'PasswordChanged'">
    <h1 style="text-align: center">
      {{ 'emailvalidation.changed' | translate }}
    </h1>

    <div fxLayout="row wrap" fxLayoutAlign="space-around start">
      <mat-card class="appt-card" style="text-align: center">
        <mat-card-title>{{
          'emailvalidation.registeredCG' | translate
        }}</mat-card-title>
        <div class="mb-7">&nbsp;</div>
        <mat-card-actions>
          <button mat-raised-button color="primary" (click)="cgLogin()">
            {{ 'emailvalidation.loginCG' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
      <mat-card class="appt-card" style="text-align: center">
        <mat-card-title>{{
          'emailvalidation.registeredCS' | translate
        }}</mat-card-title>
        <div class="mb-7">&nbsp;</div>
        <mat-card-actions>
          <button mat-raised-button color="warn" (click)="csLogin()">
            {{ 'emailvalidation.loginCS' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </ng-container>

  <div class="mb-7">&nbsp;</div>
</div>
